<template>
  <div class="h-800">

    <Carousel></Carousel>

    <div class="pt-4 lg:flex lg:flex-row gap-x-4">
      <div class="lg:basis-1/2 mt-4">
        <Banner :imgsrc="topBanner1" link="/clothes"></Banner>
      </div>
      <div class="lg:basis-1/2 mt-4">
        <Banner :imgsrc="topBanner2" link="/creators/signup"></Banner>
      </div>
    </div>

    <div class="lg:flex lg:flex-row">
      <div class="lg:basis-3/4">
        <ItemList title="おすすめ商品" :pageinfo="splProductsInfo" :pageLimit="15"/>
        <ItemList title="新着商品" :pageinfo="newProductsInfo" :pageLimit="50"/>
      </div>
      <div class="lg:basis-1/4">
        <RightSideBar></RightSideBar>
      </div>
    </div>
  </div>
</template>
<script setup>

import topBanner1 from '../assets/bn_l_01.jpg'
import topBanner2 from '../assets/bn_l_02.jpg'

const newProductsInfo = reactive({
  items: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  totalItems: 1,
  currentItems: 0
})

const splProductsInfo = reactive({
  items: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  totalItems: 1,
  currentItems: 0
})

const runtimeConfig = useRuntimeConfig()

const productsLoader = async (pageinfo, page, limit, query) => {
  const params = []
  params.push((page) ? `page=${page}` : 'page=1')
  params.push((limit) ? `l=${limit}` : `limit=${pageinfo.limit}`)
  if (query) {
    Object.keys(query).filter((key) => {
      return (query[key])  
    })
    .map((key) => `${key}=${query[key]}`)
    .forEach(param => params.push(param))
  }

  const url = `${runtimeConfig.public.apiBase}/pub/products?${params.join('&')}`

  const { data, pending, error, refresh } = await useFetch(url)
  if (error.value) {
    console.log(`error: ${error.value}`);
  } else {
    pageinfo.items        = data.value.payload.items
    pageinfo.page         = data.value.payload.page
    pageinfo.totalPages   = data.value.payload.total_pages
    pageinfo.totalItems   = data.value.payload.total_items
    pageinfo.currentItems = data.value.payload.items.length
  }
}

productsLoader(splProductsInfo, 1, 10, {recommend1: 1})
productsLoader(newProductsInfo, 1, 10)
</script>